import { AfterViewInit, Component,ChangeDetectorRef, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { FormService } from '../service/form.service';
import { environment } from '../../environments/environment';
import { getPostcode, getuserId } from '../helpers/utils';
import { reporting } from '../helpers/reporting';
import { HomeComponent } from '../home/home.component';


@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrl: './details.component.css'
})
export class DetailsComponent implements OnInit {

  applicationData: any;
  apiFailure: boolean = false;
  error_message!: string;
  error: any =  {};
  userForm: any = {
    srw_email_address: '',
    srw_mobile: '',
    srw_bills_via_email: '',
    srw_bills_via_sms: '',
    srw_reminders_via_email: '',
    srw_reminders_via_sms: '',
    srw_details_accurate: ''
  }


  constructor(private homeComp: HomeComponent,private route: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef,private formService:FormService,private router: Router) {
    this.formService.getAppData.subscribe(data => this.applicationData = data);
  }
  ngOnInit(): void {
    this.apiFailure = false;
    this.homeComp.showErrorMsg = false;
    this.homeComp.triggerChangeDetection();
    reporting();
    if(Object.keys(this.applicationData).length === 0) {
      let formId = getuserId();
      let postCode = getPostcode();
      if(formId && postCode) {
        this.formService.getFormData(formId, environment.APP_CLIENTID, postCode).subscribe(data => {
          this.formService.setAppData({postCode: postCode, ...data});
          this.applicationData = {postCode: postCode, ...data};
          this.prepopulateForm();
        },error=>{
          console.log('error', error);
          this.apiFailure = true;
          this.error_message = environment.APP_MESSAGE_NOT_FOUND;
        })
      }
    } else{
      this.prepopulateForm();
    }
  }

prepopulateForm () {
  this.userForm.srw_email_address = this.applicationData?.srw_email_address;
  this.userForm.srw_mobile = this.applicationData?.srw_mobile;
  if (this.applicationData?.srw_bills_via == 'sms') {
    this.userForm.srw_bills_via_sms = true;
  } else if (this.applicationData?.srw_bills_via == 'email') {
    this.userForm.srw_bills_via_email = true;
  }
  if (this.applicationData?.srw_reminders_via == 'sms') {
    this.userForm.srw_reminders_via_sms = true;
  } else if (this.applicationData?.srw_reminders_via == 'email') {
    this.userForm.srw_reminders_via_email = true;
  }
}

  triggerChangeDetection () {
    this.changeDetectorRef.detectChanges();
  }

  onOptionChange (event: any, fieldName: string) {
    if (fieldName == 'srw_bills_via_sms') {
      this.userForm.srw_bills_via_sms = true;
      this.userForm.srw_bills_via_email = false
    } else if (fieldName == 'srw_bills_via_email') {
      this.userForm.srw_bills_via_sms = false;
      this.userForm.srw_bills_via_email = true
    } if (fieldName == 'srw_reminders_via_sms') {
      this.userForm.srw_reminders_via_sms = true;
      this.userForm.srw_reminders_via_email = false;
    } else if(fieldName == 'srw_reminders_via_email') {
      this.userForm.srw_reminders_via_sms = false;
      this.userForm.srw_reminders_via_email = true;
    }
    this.triggerChangeDetection();
  }

  onSubmit(frm: NgForm): void {
    this.apiFailure = false;
    if (frm.invalid) {
      Object.values(frm.controls).forEach(control => {
        control.markAsTouched();
      })
    } else {
 
      let data  = {
        "client": environment.APP_CLIENTID,
        "srw_postcode": this.applicationData.postCode || getPostcode(),
        "srw_email_address": this.userForm.srw_email_address,
        "srw_mobile": this.userForm.srw_mobile,
        "srw_details_accurate": this.userForm.srw_details_accurate,
        "srw_bills_via": this.userForm.srw_bills_via_sms ? 'sms' : 'email',
        "srw_reminders_via": this.userForm.srw_reminders_via_sms ? 'sms' :  'email'
      }
      this.formService.checkSession(this.applicationData.formId || getuserId()).subscribe(sessionRes => {
        this.formService.saveFormData(this.applicationData.formId || getuserId(), data).subscribe(res => {
          this.formService.setAppData({...this.applicationData, ...data});
          this.router.navigate(['/thankyou'])
        },error=>{
          this.apiFailure = true;
          this.error_message = environment.APP_MESSAGE_API_FAILURE;
          console.log('error', error);
        })
      },error=>{
        this.apiFailure = true;
        this.error_message = environment.APP_MESSAGE_API_FAILURE;
        console.log('error', error);
      })
    }
  }
}
