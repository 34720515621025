<div *ngIf="apiFailure || showError" class="row on-error page-active">
    <div class="col-lg-12"> &nbsp;<br>
        <div class="alert alert-danger col-sm-12 col-md-10 col-lg-6" role="alert" [innerHTML]="error_message"> 
        </div>
    </div>
</div>
<div *ngIf="!showError">
    <div class="row">
        <div class="col-md-10 mb-5">
                <div class="p-3 header-text">Your identity has been verified.</div>
        </div>
        <p>Your property address details:<br>{{applicationData?.sr_address}}</p>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <p>Choose from the following options:</p>
        </div>
    </div>
    <div class="row gy-4" [hidden] = showAddToCalBtn>
          <div class="col-md-5">
            <button class="text-white border w-100 cardoptions" [disabled]="!enableViewBillBtn"  (click) ="downloadBill()"><img src="../../assets/images/Icons-E__Bill 1.svg"/><span class="px-3" style="font-weight: 600;">View bill</span><img src="../../assets/images/arrowup.svg"/></button>
          </div>
          <div  class="col-md-5">
            <button class="text-white border addeventatc w-100 cardoptions"><img src="../../assets/images/Icons-E__request 1.svg"/><span class="px-3" style="font-weight: 600;">Add calendar reminder</span> 
              
                    <span class="start">{{applicationData?.sr_calendar_start}}</span>
                    <!-- <span class="end">2020-10-21 09:00</span> -->
                    <span class="timezone">Australia/Brisbane</span>
                    <span class="title">Urban Utilities payment due {{applicationData?.sr_due_date}}.</span>
                    <span class="calname">UU-Reminder</span>
                    <span class="transp">TRANSPARENT</span>
                    <span class="description">         
                        Payment of your Urban Utilities account is due today.<br><br>
                        Amount due {{applicationData?.sr_calendar_amount}}<br><br>
                        <span *ngIf="!applicationData?.sr_direct_debit">Pay now {{applicationData?.sr_pay_url}}</span>
                        <span *ngIf="applicationData?.sr_direct_debit">Direct Debit payment of {{applicationData?.sr_calendar_amount}} will be automatically deducted from your nominated account today. </span>
                        <br><br>
                        https://urbanutilities.com.au/</span>
            
                <img  src="../../assets/images/arrowup.svg"/>
            </button>
          </div>
          
          <div *ngIf="showPayNowOpt" class="col-md-5">
            <a target="_blank" class="text-white border w-100 cardoptions" role="button" href="{{applicationData?.sr_pay_url}}" ><img src="../../assets/images/Icons-E__finance 1.svg"/><span class="px-3">Pay now</span><img  src="../../assets/images/arrowup.svg"/></a>
          </div>
          <div class="col-md-5">
            <a target="_blank" class="text-white border w-100 cardoptions" role="button" href="{{applicationData?.sr_pipeline_url}}"><img src="../../assets/images/Icons-E__Newsletter 1.svg"/><span class="px-3">Read the Pipeline blog</span><img  src="../../assets/images/arrowup.svg"/></a>
          </div>
    </div>
    <hr class="mb-4 mt-5">
    <div class ="row">
        <div class="col-xs-12 col-md-6 col-lg-6">
            <iframe [src]='safeURL' width="100%" height="300px" frameborder="0" allowfullscreen></iframe>
        </div>
        <div class="col-xs-12 col-md-6 col-lg-6">
            <h1>Your bill explained </h1>
            <p>Need help understanding your bill? <a target="_blank" href="https://www.urbanutilities.com.au/residential/accounts-and-billing/your-bill-explained">This video</a> explains each of the items on your bill, and the services that each charge helps pay for.</p>
            <!-- <button class="btn btn-primary rounded-pill" (click)="watchVideo()">WATCH THE VIDEO</button> -->
        </div>
    </div>
    <hr class="mb-5 mt-4">
    <div class="row">
        <div class="col-xs-12 mb-4">
            <h1>Keep us updated</h1>
            <p>Have we got your most recent contact details? You can update your info here.</p>
            <button class="btn btn-primary rounded-pill" (click) ="goToDetailsPage()">UPDATE YOUR DETAILS</button>
        </div>
        <div *ngIf="!applicationData?.sr_direct_debit" class="col-xs-12 mt-4 mb-4">
            <h1>Set and forget with Direct Debit</h1>
            <p>You can set up a Direct Debit and manage your account any time anywhere via <a class="click-me" target="_blank" href="https://urbanutilities.com.au/login">My account</a>.</p>
        </div>
        <div class="col-xs-12 mt-4 mb-4">
            <h1>Any questions?</h1>
            <p><a target="_blank" href="https://www.urbanutilities.com.au/contact-us/faqs" class="click-me" id="faq-link">Check out our FAQs.</a> You can also chat with us on during the week: on <a target="_blank" href="https://www.urbanutilities.com.au/contact-us">webchat</a> from 7am-7pm or phone <a href="tel:132657" class="click-me" id="phone-link">13&nbsp;26&nbsp;57</a>, 8am-5pm weekdays.</p>
        </div>
    </div>
</div>


