

<div class="row on-error page-active" *ngIf ="showError">
    <div class="col-lg-12"> &nbsp;<br>
        <div class="alert alert-danger col-md-12 col-lg-6" role="alert" [innerHTML]="error_message"></div>
    </div>
</div>

<div *ngIf="!showError">
    <div class="row">
        <div class="col-sm-12 mb-3">
            <h2 class="mb-3">Thank you</h2>
            <div class="alert alert-dark text-white p-4 col-sm-12 col-md-6 col-lg-4" role="alert">
                <i class="fas fa-check-circle"></i> Your details have been saved.
            </div>
            <p>Thanks for confirming your contact details and preferences. </p>
            <p role="alert"><i class="fas fa-info-circle"></i> BPAY View customers: if you have opted to receive your bills via Email or SMS, you will no longer receive your bills through BPAY View. </p>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 mb-3">
            <div class="form-group">
                <button class="btn btn-primary rounded-pill" (click)="backToMainMenu()">Return to main menu</button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 mb-3">
            <h4>Any questions?</h4>
            <p><a target="_blank" href="https://www.urbanutilities.com.au/contact-us/faqs" class="click-me" id="faq-link">Check out our FAQs.</a> You can also chat with us on during the week: on <a target="_blank" href="https://www.urbanutilities.com.au/contact-us">webchat</a> from 7am-7pm or phone <a href="tel:132657" class="click-me" id="phone-link">13&nbsp;26&nbsp;57</a>, 8am-5pm weekdays.</p>
        </div>
    </div>
</div>