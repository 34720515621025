import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {formURL, getuserId} from '../helpers/utils'


@Injectable({
    providedIn: 'root'
})
export class FormService{
    constructor(private http: HttpClient) { }

    private appData = new BehaviorSubject({});
    getAppData = this.appData.asObservable();

    setAppData(data: any) {
      this.appData.next({...data});
    }
    
  public createSecureSession(formId: string, clientId?: string, postCode?: number):  Observable<any> {
    let data = { 
      "client": clientId,
      "answers": [{"a_postcode": postCode}]
    };
    return this.http.post(formURL("postSecureSession",formId), JSON.stringify(data)
      ).pipe(
        map((res: any) => {
          return res || {}
        }),
        catchError(this.errorHandling)
      )
}

    public getFormData(formId: string, clientId?: string, postCode?: number):  Observable<any> {
        let params;
        if (clientId && postCode) {
            params = {
                "client": clientId,
                "a_postcode": postCode
            }
        }
        return this.http.get(formURL("getData",formId),{ params}
          ).pipe(
            map((res: any) => {
              return res || {}
            }),
            catchError(this.errorHandling)
          )
    }

    public checkSession(formId: string):  Observable<any> {
      return this.http.get(formURL("checkSession",formId)
        ).pipe(
          map((res: any) => {
            return res || {}
          }),
          catchError(this.errorHandling)
        )
  }

  public endSession(formId: string):  Observable<any> {
    return this.http.delete(formURL("endSession",formId)
      ).pipe(
        map((res: any) => {
          return res || {}
        }),
        catchError(this.errorHandling)
      )
}
    public getDocument(formId: string, clientId?: string, postCode?: number,docId?: any,docType?: any):  Observable<any> {
      let params;
      if (clientId && postCode) {
          params = {
              "client": clientId,
              "a_postcode": postCode,
          }
      }
      let headers = new HttpHeaders();
      headers = headers.set('Accept', 'application/pdf');
      return this.http.get(formURL("getDocument",formId,docType),{ params,  headers: headers, responseType: 'text'  },
        ).pipe(
          map((res: any) => {
            return res || {}
          }),
          catchError(this.errorHandling)
        )
  }
    
    public saveFormData(formId: string, data: any):  Observable<any> {
      let params;
      return this.http.post(formURL("postData",formId),JSON.stringify(data)
        ).pipe(
          map((res: any) => {
            return res || {}
          }),
          catchError(this.errorHandling)
        )
  }


    errorHandling(error: any) {
      console.log('error', error);
        let errorMessage = error?.error?.code;
        console.log(errorMessage);
        return throwError(error.error);
      }
}