import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { reporting } from '../helpers/reporting';
import { HomeComponent } from '../home/home.component';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrl: './pagenotfound.component.css'
})
export class PagenotfoundComponent implements OnInit{

  constructor(private homeComp: HomeComponent) {
   
  }
  
 ngOnInit(): void {
  this.homeComp.showErrorMsg = false;
  this.homeComp.triggerChangeDetection();
  reporting();
 }
 error_message = environment.APP_MESSAGE_NOT_FOUND;
}
