import { AfterViewChecked, AfterViewInit, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormService } from '../service/form.service';
import { Router } from '@angular/router';
import { HomeComponent } from '../home/home.component';
import { getPostcode, getuserId } from '../helpers/utils';
import { environment } from '../../environments/environment';
import { isSI } from '../helpers/utils';
declare let addevent: any; 

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrl: './billing.component.css'
})
export class BillingComponent implements OnInit, AfterViewInit, AfterViewChecked  {
  safeURL: any;
  applicationData: any;
  scriptElement!: HTMLScriptElement;
  showAddToCalBtn: boolean = true;
  error_message!: string;
  error: any =  {};
  showError: boolean =false;
  apiFailure: boolean =false;
  showPayNowOpt: boolean = false;
  showAddToCal: boolean =false;
  fileURL: any;
  pdfBlob!: Blob;
  enableViewBillBtn: boolean = false;
  
  constructor(private homeComp: HomeComponent,private _sanitizer: DomSanitizer, private formService: FormService, private router: Router){
    this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(environment.VIDEO_LINK);
    this.formService.getAppData.subscribe(data => this.applicationData = data);
    this.scriptElement = document.createElement("script");
    this.scriptElement.src = "https://addevent.com/libs/atc/1.6.1/atc.min.js";
    document.body.appendChild(this.scriptElement);
    
 }
  ngAfterViewChecked(): void {
    setTimeout(() => {
      let element = document.getElementsByClassName('addeventatc_icon') as HTMLCollectionOf<HTMLElement>;
      element[0]?.remove();
      this.showAddToCalBtn = false;
    },1000);
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      let element = document.getElementsByClassName('addeventatc_icon') as HTMLCollectionOf<HTMLElement>;
      element[0]?.remove();
      this.showAddToCalBtn = false;
    },1000);
  }
  ngOnInit(): void {
    this.showError = false;
    this.apiFailure = false;
    this.homeComp.showErrorMsg = false;
    this.homeComp.triggerChangeDetection();
    let formId = this.applicationData.formId|| getuserId();
    let postCode = this.applicationData.postCode || getPostcode();
    this.formService.checkSession(formId).subscribe(sessionRes => {
    // page is rfreshed call api again
    if(Object.keys(this.applicationData).length === 0) {
      if(formId && postCode) {
        this.formService.getFormData(formId, environment.APP_CLIENTID, postCode).subscribe(data => {
          this.formService.setAppData({postCode: postCode, ...data});
          this.applicationData = {postCode: postCode, ...data};
          this.showPayNowOpt = this.applicationData.or_flow_type == '1' || this.applicationData.or_flow_type == '4' ;
          this.showAddToCal = this.applicationData.or_flow_type == '1' || this.applicationData.or_flow_type == '2' ;
          this.downloadDoc();
        },error=>{
          console.log('error', error);
            this.apiFailure = true;
            this.error_message = environment.APP_MESSAGE_NOT_FOUND;
        })
      }
    } else {
        this.showPayNowOpt = this.applicationData.or_flow_type == '1' || this.applicationData.or_flow_type == '4';
        this.showAddToCal = this.applicationData.or_flow_type == '1' || this.applicationData.or_flow_type == '2' ;
        this.downloadDoc();
      }
    }, error =>{
      this.error_message = environment.APP_MESSAGE_COOKIE_EXPIRED;
      this.showError = true;
    })
  }

  watchVideo () {
    window.open(environment.VIDEO_LINK, "_blank");
  }
  downloadDoc () {
    this.formService.getDocument(this.applicationData.formId|| getuserId(), environment.APP_CLIENTID, this.applicationData.postCode || getPostcode(), this.applicationData.doc_id_1,this.applicationData.doc_type_1).subscribe(data =>{
      let decode = atob(data.replace(/\s/g, ''));
      let len = decode.length;
      let buffer = new ArrayBuffer(len);
      let output = new Uint8Array(buffer);
      for (var i = 0; i < len; i++) {
          output[i] = decode.charCodeAt(i);
      }
      // create blob
      this.pdfBlob = new Blob([output], {
          type: "application/pdf"
      });
      this.fileURL = URL.createObjectURL(this.pdfBlob);
      this.enableViewBillBtn = true;
    })
  }
  goToDetailsPage () {
    this.router.navigate(['/details'])
  }

  downloadBill() {
    if (isSI()) {
      console.log('Samsung Interent detected');
      // a const to create a link in the page, attach the blob, then force a click
      const downloadBlob = (fileName: string, blob: Blob) => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.target = '_blank';
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
      }

      // run the const, supply the filename and blob
      downloadBlob('UU-bill.pdf', this.pdfBlob)

  } else {
    window.open(this.fileURL);
  }
   
  }
}
