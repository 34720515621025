<div>
    <div class="row">
        <div class="col-lg-12">
            <h2 class="mb-3">Review and confirm contact details</h2>
            <p>Please update and confirm your property at: <br> {{applicationData?.sr_address}}
            </p>
            <p>Name: {{applicationData?.sr_full_name}}
            </p>
        </div>
    </div>
    <form #userDataForm="ngForm"  name="userForm" (ngSubmit)="onSubmit(userDataForm)">
            <div class="row mb-3">
                <div class="col-sm-12">
                    <label for="srw_email_address">Email address</label>
                    <!-- <label for="srw_email_address">Is this your current email address? If not, please update it here.</label> -->
                    <div class="form-group">
                        <input id="srw_email_address" (change)="triggerChangeDetection()" name="srw_email_address" type="text" class="form-control col-sm-12 col-md-6" [class.is-invalid]="srw_email_address.invalid && (srw_email_address.dirty || srw_email_address.touched)"  [(ngModel)]="userForm.srw_email_address" #srw_email_address="ngModel" email [required]="(userForm.srw_bills_via_email || userForm.srw_reminders_via_email ) ? 'true': 'false' ">
                        <div
                        *ngIf="userDataForm.submitted || srw_email_address.invalid && (srw_email_address.dirty || srw_email_address.touched)"
                        >
                            <div class="invalid-feedback d-block" *ngIf="srw_email_address.errors?.['required']">You must supply an email address, please try again.</div>
                            <div class="invalid-feedback d-block" *ngIf="srw_email_address.errors?.['email']">Your entry must be a valid email address, please try again.</div>  
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-12">
                    <label for="srw_mobile">Mobile phone number</label>
                    <!-- <label for="srw_mobile">Is this your current mobile phone number? If not, keeping it current helps us contact you in an emergency.</label> -->
                    <div class="form-group">
                        <input id="srw_mobile" name="srw_mobile" (change)="triggerChangeDetection()" type="text" class="form-control col-sm-12 col-md-6" maxlength="10"  [(ngModel)]="userForm.srw_mobile" #srw_mobile="ngModel" [class.is-invalid]="srw_mobile.invalid && (srw_mobile.dirty || srw_mobile.touched)" [required]="(userForm.srw_bills_via_sms || userForm.srw_reminders_via_sms) ? 'true': 'false' " [pattern]="(userForm.srw_bills_via_sms || userForm.srw_reminders_via_sms || userForm.srw_mobile )? '^04[0-9]{8}$' : ''">
                        <div
                        *ngIf="userDataForm.submitted || srw_mobile.invalid && (srw_mobile.dirty || srw_mobile.touched)"
                        >
                            <div class="invalid-feedback d-block" *ngIf="srw_mobile.errors?.['required'] && (userForm.srw_bills_via_sms || userForm.srw_reminders_via_sms)">You must supply a phone number, please try again.</div>
                            <div class="invalid-feedback d-block" *ngIf="srw_mobile.errors?.['pattern'] && (userForm.srw_bills_via_sms || userForm.srw_reminders_via_sms || userForm.srw_mobile)">Your entry must be a valid mobile phone number, please try again.</div>  
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-12">
                    <h2>Communication preferences</h2>
                    <div class="form-group">
                        <label for="">I'd like to receive my future bills via</label>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="srw_bills_via" id="srw_bills_via_email" [value]="userForm.srw_bills_via_email" (change) ="onOptionChange($event, 'srw_bills_via_email')" [attr.checked] ="applicationData?.srw_bills_via =='email' ? 'checked' : null">
                            <label class="form-check-label" for="srw_bills_via_email">Email</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="srw_bills_via" id="srw_bills_via_sms" [value]="userForm.srw_bills_via_sms" (change) ="onOptionChange($event, 'srw_bills_via_sms')" [attr.checked] ="applicationData?.srw_bills_via =='sms' ? 'checked' : null">
                            <label class="form-check-label" for="srw_bills_via_sms">SMS</label>
                        </div>
                        <div role="alert" class="invalid-feedback d-none" id="billsviaempty">You must select an option, please try again.</div>
                    </div>
                    <div class="form-group">
                        <label for="">I'd like to receive bill reminders via</label>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="srw_reminders_via" id="srw_reminders_via_email" [value]="userForm.srw_reminders_via_email" (change) ="onOptionChange($event, 'srw_reminders_via_email')" [attr.checked] ="applicationData?.srw_reminders_via =='email' ? 'checked' : null" >
                            <label class="form-check-label" for="srw_reminders_via_email">Email</label>
                        </div>

                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="srw_reminders_via" id="srw_reminders_via_sms" [value]="userForm.srw_reminders_via_sms" (change) ="onOptionChange($event, 'srw_reminders_via_sms')" [attr.checked] ="applicationData?.srw_reminders_via =='sms' ? 'checked' : null" >
                            <label class="form-check-label" for="srw_reminders_via_sms">SMS</label>
                        </div>
                    </div>
                    <div class="custom-control custom-checkbox mb-3 mt-3">
                        <input type="checkbox" name="srw_details_accurate" class="custom-control-input srw_details_accurate" id="srw_details_accurate" required [(ngModel)]="userForm.srw_details_accurate" #srw_details_accurate="ngModel">
                        <label class="custom-control-label" for="srw_details_accurate">I confirm these details are accurate and true.</label>
                        <div
                        *ngIf="userDataForm.submitted || srw_details_accurate.invalid && (srw_details_accurate.dirty || srw_details_accurate.touched)"
                        >
                            <div class="invalid-feedback d-block" *ngIf="srw_details_accurate.errors?.['required']">You must agree that these details are accurate and true.</div> 
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row mb-3">
                <div class="col-sm-12">
                    <h2>Would you like to receive your bills by email or SMS?</h2>
                    <div class="custom-control custom-checkbox">
                        <div class="form-check form-check-inline">
                        <input type="checkbox"  class="custom-control-input srw_bills_via_email" name="srw_bills_via_email" id="srw_bills_via_email" (change)="triggerChangeDetection()" [(ngModel)]="userForm.srw_bills_via_email" #srw_bills_via_email="ngModel" [required]="!userForm.srw_bills_via_sms" value="Email">
                        <label class="custom-control-label" for="srw_bills_via_email">Email</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input type="checkbox" name="srw_bills_via_sms" class="custom-control-input srw_bills_via_sms" id="srw_bills_via_sms" (change)="triggerChangeDetection()"  [(ngModel)]="userForm.srw_bills_via_sms" #srw_bills_via_sms="ngModel" [required]="!userForm.srw_bills_via_email" value="SMS">
                            <label class="custom-control-label" for="srw_bills_via_sms">SMS</label>
                        </div>
                    </div>
                    <div
                    *ngIf="userDataForm.submitted || srw_bills_via_email.invalid && (srw_bills_via_email.dirty || srw_bills_via_email.touched)"
                    >
                    <div class="invalid-feedback d-block mb-3 custom-error" *ngIf="srw_bills_via_email.errors?.['required'] || srw_bills_via_email.errors?.['required']">You must select a communication preference.</div> 
                    </div>
                    
                    <div class="custom-control custom-checkbox mb-3 mt-3">
                        <input type="checkbox" name="srw_details_accurate" class="custom-control-input srw_details_accurate" id="srw_details_accurate" required [(ngModel)]="userForm.srw_details_accurate" #srw_details_accurate="ngModel">
                        <label class="custom-control-label" for="srw_details_accurate">I confirm these details are accurate and true.</label>
                        <div
                        *ngIf="userDataForm.submitted || srw_details_accurate.invalid && (srw_details_accurate.dirty || srw_details_accurate.touched)"
                        >
                            <div class="invalid-feedback d-block" *ngIf="srw_details_accurate.errors?.['required']">You must agree that these details are accurate and true.</div> 
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <button id="updatebutton" type="submit" class="btn btn-primary rounded-pill">Update</button>
                    </div>
                </div>
            </div>
    </form>
</div>

<div *ngIf="apiFailure" class="row on-error page-active">
    <div class="col-lg-12"> &nbsp;<br>
        <div class="alert alert-danger col-sm-12 col-md-10 col-lg-6" role="alert" [innerHtml] ="error_message">
        </div>
    </div>
</div>
