<div class="d-flex flex-column h-100">
    <div class="flex-shrink-0">
        <div class="container">
            <div class="page-header mt-4 mb-4" id="banner">
                <div class="row">
                    <div class="col-lg-8 col-md-7 col-sm-6">
                        <div class="logo barebone-header"><img src="../assets/logo.svg" alt="Queensland Urban Utilities Logo"></div>
                    </div>
                </div>
            </div>

            <div *ngIf="showErrorMsg" class="row on-error page-active home_error">
                <div class="col-lg-12"> &nbsp;<br>
                    <div class="alert alert-danger col-sm-12 col-md-8" role="alert" [innerHTML]="error_message">
                    </div>
                </div>
            </div>
            <div *ngIf="showIEErrorMsg">
                <div class="row  on-error page-active on-IE">
                    <div class="col-lg-12"> &nbsp;<br>
                        <div class="alert alert-danger col-sm-12 col-md-8" role="alert">
                            Your web browser is out of date. <br> Update your browser for more security, speed and the best
                            experience on this site. <br>
                        </div>
                    </div>
                </div>
                <div class="row  page-active on-IE">
                    <div class="col-sm-12 col-md-12">
        
                        <p>Your web browser (Internet Explorer) is out of date.</p>
                        <p>Please update to one of these current browsers:</p>
                        <div class="row mb-5">
        
                            <div class="col-sm-6 col-lg-4 mt-3">
                                <div class="card">
                                    <div class="card-body text-center">
                                        <i class="fab fa-edge fa-5x mt-3 mb-2 "></i>
                                        <div class="card-body">
                                            <h5 class="card-title">Microsoft Edge</h5>
                                            <p class="card-text">From Microsoft</p>
                                            <a href="https://www.microsoft.com/edge" class="btn btn-outline-primary">Get
                                                Edge</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-4 mt-3">
                                <div class="card">
                                    <div class="card-body text-center">
                                        <i class="fab fa-chrome fa-5x mt-3 mb-2 "></i>
                                        <div class="card-body">
                                            <h5 class="card-title">Chrome</h5>
                                            <p class="card-text">From Google</p>
                                            <a href="https://www.google.com/chrome/browser/desktop/"
                                                class="btn btn-outline-primary">Get Chrome</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-4 mt-3">
                                <div class="card">
                                    <div class="card-body text-center">
                                        <i class="fab fa-firefox fa-5x mt-3 mb-2 "></i>
                                        <div class="card-body">
                                            <h5 class="card-title">Firefox</h5>
                                            <p class="card-text">From Mozilla Foundation</p>
                                            <a href="https://www.mozilla.org/firefox/new" class="btn btn-outline-primary">Get
                                                Firefox</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p>These links provided will direct you to each vendor's official website.</p>
                    </div>
                </div>
            </div>
            <div *ngIf="!hardstop">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>