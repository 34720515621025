import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormService } from '../service/form.service';
import { environment } from '../../environments/environment';
import { renderError, setuserId, removeuserId,setPostcode,removePostcode } from '../helpers/utils';
import { reporting } from '../helpers/reporting';
import { NgForm } from '@angular/forms';
import { HomeComponent } from '../home/home.component';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.css'
})
export class WelcomeComponent implements OnInit {
  postCode!: number;
  formId!: string;
  error_message!: string;
  error: any =  {};
  showError: boolean =false;
  ngForm: any;

  @ViewChild('welcomeForm') public welcomeForm!: NgForm;

  constructor(private homeComp: HomeComponent, private route: ActivatedRoute, private http:HttpClient, private formService:FormService, private router: Router) {

  }
  ngOnInit(): void {
    this.homeComp.showErrorMsg = false;
    this.homeComp.triggerChangeDetection();
    removeuserId('USER_ID');
    removePostcode('POST_CODE');
    reporting();
    this.showError = false;
    this.formId = this.route.snapshot.params['id'];
    setuserId(this.formId)
    this.formService.getFormData(this.formId).subscribe(data => {
    },error=>{
      this.showError = true;
      console.log('error', error);
      this.error = renderError(error?.code);
      this.error_message = this.error?.error_message;
    });

  }

 verifyPostcode(frm: NgForm) {
  if (frm.invalid) {
    Object.values(frm.controls).forEach(control => {
      control.markAsTouched();
    })
  } else {
 this.formService.createSecureSession(this.formId, environment.APP_CLIENTID, this.postCode).subscribe(data => {
      this.formService.getFormData(this.formId, environment.APP_CLIENTID, this.postCode).subscribe(data => {
        this.formService.setAppData({postCode: this.postCode, ...data})
        setPostcode(this.postCode);
        this.router.navigate(['/billing'])
      },error=>{
        console.log('error', error);
        if (error?.code == 'UNAUTHORIZED') {
          this.welcomeForm.form.controls['postcode'].setErrors({'incorrect': true});
        } else {
          this.showError = true;
          this.error = renderError(error?.code);
          this.error_message = this.error?.error_message;
        }
      })
   },error=>{
    console.log('error', error);
    this.welcomeForm.form.controls['postcode'].setErrors({'incorrect': true});
    if (error?.code == 'UNAUTHORIZED') {
      this.welcomeForm.form.controls['postcode'].setErrors({'incorrect': true});
    } else {
      this.showError = true;
      this.error = renderError(error?.code);
      this.error_message = this.error?.error_message;
    }
  })
  }
  }
}
