import { environment } from '../../environments/environment';
import {getuserId} from './utils';
import jquery from 'jquery';


declare global {
    interface Window {
    dataLayer: any;
    }
  }



// function to build page tracking and reporting features

export function reporting() {

    // function to create a valid tracking pixel and append to the body of each page as it is visited
    // Get the current full URL - encode it to be safe
    var currentpage = encodeURIComponent(window.location.href);
    // Create a tracking image URL
    var trackingimageSRC = environment.SITE_BASE_URL + "/open?client=" + environment.APP_CLIENTID + "&amp;id=" + getuserId() + "&amp;formId=" + getuserId() + "&amp;url=" + currentpage;
    // Create a tracking image, ready for appending
    var trackingimage = "<img height='1' width='1' style='display:none; font-size:0;' alt='' src='" + trackingimageSRC + "'>";
    // Append image at bottom of screen
    jquery("body").append(trackingimage);
    
    // Google tracking
    if (environment.GOOGLEUA != '') {
        var script = document.createElement("script");
        script.src = "https://www.googletagmanager.com/gtag/js?id=" + environment.GOOGLEUA;
        document.body.appendChild(script);
    }

    window.dataLayer = window.dataLayer || [];

    function gtag(...args: any[]) { window.dataLayer.push(arguments) }
    gtag("js", new Date());
    gtag("config", environment.GOOGLEUA);

}







